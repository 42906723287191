import React, { useEffect } from "react";

function Login() {

  useEffect(() => {
    window.location.href = "https://smafleet.com";
  }, []);

  return (
    <div>
      <h2>Login</h2>
    </div>
  );
}

export default Login;