import React, { useEffect } from "react";
import { Switch, Route, useLocation } from "react-router-dom";

import "./css/style.scss";

import AOS from "aos";
import { focusHandling } from "cruip-js-toolkit";
import Home from "./pages/Home";

function App() {
  const location = useLocation();

  useEffect(() => {
    AOS.init({
      once: true,
      disable: "phone",
      duration: 700,
      easing: "ease-out-cubic",
    });
  });

  useEffect(() => {
    var pageLink = document.querySelectorAll(".page-scroll");

    pageLink.forEach((elem) => {
      elem.addEventListener("click", (e) => {
        e.preventDefault();
        document.querySelector(elem.getAttribute("href")).scrollIntoView({
          behavior: "smooth",
          offsetTop: 1 - 60,
        });
      });
    });
  }, [location.pathname]); // triggered on route change

  useEffect(() => {
    // section menu active
    const onScroll = (event) => {
      const sections = document.querySelectorAll(".page-scroll");
      const scrollPos =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;

      for (var i = 0; i < sections.length; i++) {
        var currLink = sections[i];
        var val = currLink.getAttribute("href");
        var refElement = document.querySelector(val);
        var scrollTopMinus = scrollPos + 98;
        if (
          refElement?.offsetTop <= scrollTopMinus &&
          refElement?.offsetTop + refElement.offsetHeight > scrollTopMinus
        ) {
          document.querySelector(".page-scroll").classList.remove("active");
          currLink.classList.add("active");
        } else {
          currLink.classList.remove("active");
        }
      }
    };
    window.document.addEventListener("scroll", onScroll);

    return () => window.document.removeEventListener("scroll", onScroll);
  }, []);

  useEffect(() => {
    const keyHandler = () => {
      document.querySelector(".navbar-toggler").classList.toggle("active");
      document.querySelector(".navbar-collapse").classList.toggle("show");
    };

    document
      .querySelector(".navbar-toggler")
      .addEventListener("click", keyHandler);
    return () =>
      document
        .querySelector(".navbar-toggler")
        .removeEventListener("click", keyHandler);
  }, []);

  // ===== close navbar-collapse when a  clicked

  document.querySelectorAll(".page-scroll").forEach((e) =>
    e.addEventListener("click", () => {
      document.querySelector(".navbar-toggler").classList.remove("active");
      document.querySelector(".navbar-collapse").classList.remove("show");
    })
  );
  useEffect(() => {
    document.querySelectorAll(".page-scroll").forEach((e) =>
      e.addEventListener("click", () => {
        document.querySelector(".navbar-toggler").classList.remove("active");
        document.querySelector(".navbar-collapse").classList.remove("show");
      })
    );
  }, []);

  useEffect(() => {
    window.onscroll = function () {
      var header_navbar = document.querySelector(".navigation");
      var sticky = header_navbar.offsetTop;

      if (window.pageYOffset > sticky) {
        header_navbar.classList.add("sticky");
      } else {
        header_navbar.classList.remove("sticky");
      }

      // show or hide the back-top-top button
      var backToTop = document.querySelector(".back-to-top");
      if (
        document.body.scrollTop > 50 ||
        document.documentElement.scrollTop > 50
      ) {
        backToTop.style.display = "flex";
      } else {
        backToTop.style.display = "none";
      }
    };
  }, []);

  return (
    <>
          <Home />
    </>
  );
}

export default App;

