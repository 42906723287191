import React from "react";
import Icon from "../utils/Icon";
import Consoltrack from "../utils/Consoltrack";
import Maintenance from "../utils/Maintenance";
import Invoicing from "../utils/Invoicing";
import Trips from "../utils/Trips";

function Features() {
  return (
    <section id="features" className="py-16">
      <div className="ml-5 text-center">
          <h2
            className="mb-10 border-b section-heading wow fadeInDown"
            data-wow-delay="0.3s"
          >
            Product Highlight
          </h2>
        </div>
      <Consoltrack />
      <Maintenance />
      <Invoicing />
      <Trips />
    </section>
    
  );
}

export default Features;
