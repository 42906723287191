import React from "react";
import { Link } from "react-router-dom";
import Icon from "../utils/Icon";

function Footer() {
  return (
    <footer id="footer" className="bg-gray-800 py-16">
      <div className="container">
        <div className="flex flex-wrap">
          <div
            className="w-full sm:w-1/2 md:w-1/2 lg:w-1/4 wow fadeInUp"
            data-wow-delay="0.2s"
          >
            <div className="mx-3 mb-8">
              <div className="footer-logo mb-3">
                <a className="navbar-brand flex items-center" href="#">
                <Icon name="smafleetLogo" className="w-12 h-12" />
                <span className="text-2xl text-gray-400 flex font-medium">
                  Sma <span className="text-teal-500">fleet</span>
                </span>
            </a>
              </div>
              <p className="text-gray-300">
                The most complete fleet management solution.
              </p>
              <p className="text-gray-300">
                Contact Sales +2348033326084
              </p>
            </div>
          </div>
          <div
            className="w-full sm:w-1/2 md:w-1/2 lg:w-1/4 wow fadeInUp"
            data-wow-delay="0.4s"
          >
            <div className="mx-3 mb-8">
              <h3 className="font-bold text-xl text-white mb-5">SMAFLEET</h3>
              <ul>
                <li>
                  <a href="#" className="footer-links">
                    Press Releases
                  </a>
                </li>
                <li>
                  <a href="#" className="footer-links">
                    Mission
                  </a>
                </li>
                <li>
                  <a href="#" className="footer-links">
                    Strategy
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div
            className="w-full sm:w-1/2 md:w-1/2 lg:w-1/4 wow fadeInUp"
            data-wow-delay="0.6s"
          >
            <div className="mx-3 mb-8">
              <h3 className="font-bold text-xl text-white mb-5">About</h3>
              <ul>
                <li>
                  <a href="#" className="footer-links">
                    Career
                  </a>
                </li>
                <li>
                  <a href="#" className="footer-links">
                    Team
                  </a>
                </li>
                <li>
                  <a href="#" className="footer-links">
                    Clients
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div
            className="w-full sm:w-1/2 md:w-1/2 lg:w-1/4 wow fadeInUp"
            data-wow-delay="0.8s"
          >
            <div className="mx-3 mb-8">
              <h3 className="font-bold text-xl text-white mb-5">Find us on</h3>

              <ul className="social-icons flex justify-start">
              <li className="mx-2">
                  <a href=" https://wa.me/message/R5HBTH4IVDNZL1" className="footer-icon hover:bg-red-500">
                    <i
                      className="lni lni-whatsapp"
                      aria-hidden="true"
                    ></i>
                  </a>
                </li>
                <li className="mx-2">
                  <a href="https://www.linkedin.com/company/smafleet/" className="footer-icon hover:bg-indigo-600">
                    <i
                      className="lni lni-linkedin-original"
                      aria-hidden="true"
                    ></i>
                  </a>
                </li>
                <li className="mx-2">
                  <a href="https://www.youtube.com/channel/UCRdQTO12k4fy8r4ya2OS2lg" className="footer-icon hover:bg-indigo-500">
                    <i
                      className="lni lni-youtube"
                      aria-hidden="true"
                    ></i>
                  </a>
                </li>
                <li className="mx-2">
                  <a href="#" className="footer-icon hover:bg-blue-400">
                    <i
                      className="lni lni-twitter-original"
                      aria-hidden="true"
                    ></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
