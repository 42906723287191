import React from "react";

export default ({ name, className }) => {
  if (name === "apple") {
    return (
      <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        width="100"
        height="100"
        viewBox="0 0 100 100"
      >
        <g fillRule="nonzero">
          <path d="M46.173 19.967C49.927-1.838 19.797-.233 14.538.21c-.429.035-.648.4-.483.8 2.004 4.825 14.168 31.66 32.118 18.957zm13.18 1.636c1.269-.891 1.35-1.614.047-2.453l-2.657-1.71c-.94-.607-1.685-.606-2.532.129-5.094 4.42-7.336 9.18-8.211 15.24 1.597.682 3.55.79 5.265.328 1.298-4.283 3.64-8.412 8.088-11.534z" />
          <path d="M88.588 67.75c9.65-27.532-13.697-45.537-35.453-32.322-1.84 1.118-4.601 1.118-6.441 0-21.757-13.215-45.105 4.79-35.454 32.321 5.302 15.123 17.06 39.95 37.295 29.995.772-.38 1.986-.38 2.758 0 20.235 9.955 31.991-14.872 37.295-29.995z" />
        </g>
      </svg>
    );
  }

  if (name === "book") {
    return (
      <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        <path d="M6 4H5a1 1 0 1 1 0-2h11V1a1 1 0 0 0-1-1H4a2 2 0 0 0-2 2v16c0 1.1.9 2 2 2h12a2 2 0 0 0 2-2V5a1 1 0 0 0-1-1h-7v8l-2-2-2 2V4z" />
      </svg>
    );
  }

  if (name === "cheveron-down") {
    return (
      <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
      </svg>
    );
  }

  if (name === "cheveron-right") {
    return (
      <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        <polygon points="12.95 10.707 13.657 10 8 4.343 6.586 5.757 10.828 10 6.586 14.243 8 15.657 12.95 10.707" />
      </svg>
    );
  }

  if (name === "dashboard") {
    return (
      <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        <path d="M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm-5.6-4.29a9.95 9.95 0 0 1 11.2 0 8 8 0 1 0-11.2 0zm6.12-7.64l3.02-3.02 1.41 1.41-3.02 3.02a2 2 0 1 1-1.41-1.41z" />
      </svg>
    );
  }

  if (name === "location") {
    return (
      <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        <path d="M10 20S3 10.87 3 7a7 7 0 1 1 14 0c0 3.87-7 13-7 13zm0-11a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
      </svg>
    );
  }

  if (name === "office") {
    return (
      <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        width="100"
        height="100"
        viewBox="0 0 100 100"
      >
        <path
          fillRule="evenodd"
          d="M7 0h86v100H57.108V88.418H42.892V100H7V0zm9 64h11v15H16V64zm57 0h11v15H73V64zm-19 0h11v15H54V64zm-19 0h11v15H35V64zM16 37h11v15H16V37zm57 0h11v15H73V37zm-19 0h11v15H54V37zm-19 0h11v15H35V37zM16 11h11v15H16V11zm57 0h11v15H73V11zm-19 0h11v15H54V11zm-19 0h11v15H35V11z"
        />
      </svg>
    );
  }

  if (name == "printer") {
    return (
      <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        <path d="M4 16H0V6h20v10h-4v4H4v-4zm2-4v6h8v-6H6zM4 0h12v5H4V0zM2 8v2h2V8H2zm4 0v2h2V8H6z" />
      </svg>
    );
  }

  if (name === "shopping-cart") {
    return (
      <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        <path d="M4 2h16l-3 9H4a1 1 0 1 0 0 2h13v2H4a3 3 0 0 1 0-6h.33L3 5 2 2H0V0h3a1 1 0 0 1 1 1v1zm1 18a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm10 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4z" />
      </svg>
    );
  }

  if (name === "store-front") {
    return (
      <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        <path d="M18 9.87V20H2V9.87a4.25 4.25 0 0 0 3-.38V14h10V9.5a4.26 4.26 0 0 0 3 .37zM3 0h4l-.67 6.03A3.43 3.43 0 0 1 3 9C1.34 9 .42 7.73.95 6.15L3 0zm5 0h4l.7 6.3c.17 1.5-.91 2.7-2.42 2.7h-.56A2.38 2.38 0 0 1 7.3 6.3L8 0zm5 0h4l2.05 6.15C19.58 7.73 18.65 9 17 9a3.42 3.42 0 0 1-3.33-2.97L13 0z" />
      </svg>
    );
  }

  if (name === "trash") {
    return (
      <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        <path d="M6 2l2-2h4l2 2h4v2H2V2h4zM3 6h14l-1 14H4L3 6zm5 2v10h1V8H8zm3 0v10h1V8h-1z" />
      </svg>
    );
  }

  if (name === "users") {
    return (
      <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        <path d="M7 8a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm0 1c2.15 0 4.2.4 6.1 1.09L12 16h-1.25L10 20H4l-.75-4H2L.9 10.09A17.93 17.93 0 0 1 7 9zm8.31.17c1.32.18 2.59.48 3.8.92L18 16h-1.25L16 20h-3.96l.37-2h1.25l1.65-8.83zM13 0a4 4 0 1 1-1.33 7.76 5.96 5.96 0 0 0 0-7.52C12.1.1 12.53 0 13 0z" />
      </svg>
    );
  }

  if (name === "smafleetLogo") {
    return (
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 120 140"
        className={className}
        // enable-background="new 0 0 117.259 138.579"
      >
        <path
          fill="rgba(156, 163, 175)"
          d="M24.955,117.661c-0.595-7.508-1.151-14.51-1.73-21.809
       c7.077,1.648,13.948,3.246,21.246,4.947c-2.71,2.332-5.163,4.441-7.636,6.57c9.598,8.68,30.963,8.727,44.706,0.135
       c9.059-5.662,15.094-13.744,18.086-23.998c2.975-10.195,2.078-20.158-2.338-29.956c1.809-0.865,3.521-1.684,5.369-2.566
       c0.289,0.569,0.551,1.019,0.756,1.491c8.463,19.463,3.195,42.162-13.006,56.062c-15.961,13.691-39.102,15.395-56.971,4.125
       c-1.217-0.768-1.957-0.766-2.985,0.225C28.812,114.463,27.021,115.881,24.955,117.661z"
        />
        <path
          fill="rgba(156, 163, 175)"
          d="M93.921,25.874c0.592,7.52,1.146,14.561,1.715,21.798
       c-7.119-1.658-13.998-3.259-21.275-4.954c2.736-2.354,5.172-4.45,7.82-6.729c-5.516-3.47-11.24-5.459-17.398-6.164
       c-9.901-1.133-19.117,0.942-27.553,6.254c-18.172,11.444-24.756,34.8-15.712,53.926c-1.702,0.82-3.408,1.641-5.292,2.547
       c-0.276-0.535-0.531-0.975-0.736-1.436c-8.42-18.907-3.322-41.886,12.322-55.55c16.03-14.001,39.1-16.099,56.902-5.174
       c2.309,1.417,2.309,1.417,4.371-0.358C90.578,28.752,92.07,27.467,93.921,25.874z"
        />
        <polygon
          fill="rgba(156, 163, 175)"
          points="71.718,49.229 71.625,49.385 70.974,50.472 57.579,72.847 42.716,76.883 43.729,80.618
         27.611,75.596 71.535,49.34 71.623,49.288 		"
        />
        <polygon
          fill="#3ABAB4"
          points="60.914,90.02 64.139,89.145 59.986,73.837 71.427,50.716 71.988,49.584 72.07,49.417
         72.074,49.532 72.078,49.629 73.767,100.78 		"
        />
      </svg>
    );
  }

  if (name === "powered") {
    return (
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width="358.615px"
        height="100px"
        viewBox="0 0 324.615 100"
      >
        <g>
          <g>
            <text
              className="tracking-widest"
              transform="matrix(1 0 0 1 82.2778 57.6992)"
            >
              <tspan
                x="0"
                y="0"
                fill="#0A1335"
                // font-family="'NirmalaUI-Bold'"
                fontSize="27.1968"
              >
                sma
              </tspan>
              <tspan
                x="51.512"
                y="0"
                fill="#72CCD4"
                // font-family="'NirmalaUI-Bold'"
                fontSize="27.1968"
              >
                fleet
              </tspan>
              <tspan
                x="109.676"
                y="0"
                fill="#0A1335"
                // font-family="'NirmalaUI-Bold'"
                fontSize="27.1968"
              >
                .com
              </tspan>
            </text>
            <g>
              <path
                fill="#0A1335"
                d="M18.987,71.582c-0.378-4.781-0.733-9.242-1.102-13.893
             c4.508,1.051,8.886,2.068,13.536,3.152c-1.727,1.484-3.29,2.828-4.865,4.186c6.115,5.531,19.726,5.559,28.482,0.086
             c5.771-3.607,9.615-8.756,11.522-15.289c1.895-6.495,1.323-12.842-1.49-19.083c1.152-0.552,2.244-1.072,3.421-1.636
             c0.185,0.363,0.35,0.649,0.482,0.951c5.391,12.398,2.036,26.858-8.287,35.715c-10.167,8.723-24.911,9.807-36.295,2.627
             c-0.775-0.488-1.247-0.488-1.901,0.143C21.445,69.545,20.304,70.449,18.987,71.582z"
              />
              <path
                fill="#0A1335"
                d="M62.926,13.107c0.377,4.791,0.729,9.277,1.092,13.887
             c-4.536-1.055-8.919-2.076-13.555-3.154c1.743-1.499,3.295-2.834,4.982-4.287c-3.513-2.21-7.16-3.478-11.084-3.927
             c-6.307-0.722-12.179,0.6-17.553,3.986C15.231,26.9,11.036,41.781,16.798,53.967c-1.084,0.521-2.17,1.043-3.371,1.621
             c-0.176-0.342-0.339-0.621-0.469-0.914c-5.365-12.045-2.117-26.686,7.85-35.389c10.212-8.921,24.91-10.257,36.252-3.296
             c1.471,0.902,1.471,0.902,2.785-0.228C60.795,14.942,61.748,14.123,62.926,13.107z"
              />
              <g>
                <polygon
                  fill="#0A1335"
                  points="48.781,27.986 48.72,28.086 48.307,28.779 39.771,43.033 30.303,45.604 30.949,47.982
               20.68,44.785 48.664,28.058 48.719,28.025 				"
                />
                <polygon
                  fill="#72CCD4"
                  points="41.896,53.975 43.952,53.416 41.305,43.666 48.594,28.933 48.952,28.212 49.004,28.107
               49.007,28.179 49.009,28.242 50.085,60.83 				"
                />
              </g>
            </g>
          </g>
          <g>
            <text
              transform="matrix(1 0 0 1 75.9746 75.1582)"
              fill="#0A1335"
              className="tracking-widest"
              fontSize="11.0302"
            >
              FLEET OPERATION MANAGEMENT SOLUTIONS
            </text>
          </g>
        </g>
      </svg>
    );
  }

  if (name === "smafleeticon") {
    return (
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width="80px"
        height="80px"
        // className="border border-2 rounded-full"
        viewBox="0 0 80 80"
      >
        <g>
          <g>
            <path
              fill="#0A1335"
              d="M18.987,71.582c-0.378-4.781-0.733-9.242-1.102-13.893
             c4.508,1.051,8.886,2.068,13.536,3.152c-1.727,1.484-3.29,2.828-4.865,4.186c6.115,5.531,19.726,5.559,28.482,0.086
             c5.771-3.607,9.615-8.756,11.522-15.289c1.895-6.495,1.323-12.842-1.49-19.083c1.152-0.552,2.244-1.072,3.421-1.636
             c0.185,0.363,0.35,0.649,0.482,0.951c5.391,12.398,2.036,26.858-8.287,35.715c-10.167,8.723-24.911,9.807-36.295,2.627
             c-0.775-0.488-1.247-0.488-1.901,0.143C21.445,69.545,20.304,70.449,18.987,71.582z"
            />
            <path
              fill="#0A1335"
              d="M62.926,13.107c0.377,4.791,0.729,9.277,1.092,13.887
             c-4.536-1.055-8.919-2.076-13.555-3.154c1.743-1.499,3.295-2.834,4.982-4.287c-3.513-2.21-7.16-3.478-11.084-3.927
             c-6.307-0.722-12.179,0.6-17.553,3.986C15.231,26.9,11.036,41.781,16.798,53.967c-1.084,0.521-2.17,1.043-3.371,1.621
             c-0.176-0.342-0.339-0.621-0.469-0.914c-5.365-12.045-2.117-26.686,7.85-35.389c10.212-8.921,24.91-10.257,36.252-3.296
             c1.471,0.902,1.471,0.902,2.785-0.228C60.795,14.942,61.748,14.123,62.926,13.107z"
            />
            <g>
              <polygon
                fill="#0A1335"
                points="48.781,27.986 48.72,28.086 48.307,28.779 39.771,43.033 30.303,45.604 30.949,47.982
               20.68,44.785 48.664,28.058 48.719,28.025 				"
              />
              <polygon
                fill="#72CCD4"
                points="41.896,53.975 43.952,53.416 41.305,43.666 48.594,28.933 48.952,28.212 49.004,28.107
               49.007,28.179 49.009,28.242 50.085,60.83 				"
              />
            </g>
          </g>
        </g>
      </svg>
    );
  }

  if (name === "smafleeticonsm") {
    return (
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width="39px"
        height="39px"
        // className="border border-2 rounded-full"
        viewBox="0 0 80 80"
      >
        <g>
          <g>
            <path
              fill="#fff"
              d="M18.987,71.582c-0.378-4.781-0.733-9.242-1.102-13.893
             c4.508,1.051,8.886,2.068,13.536,3.152c-1.727,1.484-3.29,2.828-4.865,4.186c6.115,5.531,19.726,5.559,28.482,0.086
             c5.771-3.607,9.615-8.756,11.522-15.289c1.895-6.495,1.323-12.842-1.49-19.083c1.152-0.552,2.244-1.072,3.421-1.636
             c0.185,0.363,0.35,0.649,0.482,0.951c5.391,12.398,2.036,26.858-8.287,35.715c-10.167,8.723-24.911,9.807-36.295,2.627
             c-0.775-0.488-1.247-0.488-1.901,0.143C21.445,69.545,20.304,70.449,18.987,71.582z"
            />
            <path
              fill="#fff"
              d="M62.926,13.107c0.377,4.791,0.729,9.277,1.092,13.887
             c-4.536-1.055-8.919-2.076-13.555-3.154c1.743-1.499,3.295-2.834,4.982-4.287c-3.513-2.21-7.16-3.478-11.084-3.927
             c-6.307-0.722-12.179,0.6-17.553,3.986C15.231,26.9,11.036,41.781,16.798,53.967c-1.084,0.521-2.17,1.043-3.371,1.621
             c-0.176-0.342-0.339-0.621-0.469-0.914c-5.365-12.045-2.117-26.686,7.85-35.389c10.212-8.921,24.91-10.257,36.252-3.296
             c1.471,0.902,1.471,0.902,2.785-0.228C60.795,14.942,61.748,14.123,62.926,13.107z"
            />
            <g>
              <polygon
                fill="#fff"
                points="48.781,27.986 48.72,28.086 48.307,28.779 39.771,43.033 30.303,45.604 30.949,47.982
               20.68,44.785 48.664,28.058 48.719,28.025 				"
              />
              <polygon
                fill="#72CCD4"
                points="41.896,53.975 43.952,53.416 41.305,43.666 48.594,28.933 48.952,28.212 49.004,28.107
               49.007,28.179 49.009,28.242 50.085,60.83 				"
              />
            </g>
          </g>
        </g>
      </svg>
    );
  }

  return null;
};
