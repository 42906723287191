import React from "react";
import Icon from "../utils/Icon";

function About() {
  return (
    <section id="about" className="py-16">
      <div className="container">
        <div className="ml-5 text-center">
          <h2
            className="mb-10 border-b section-heading wow fadeInDown"
            data-wow-delay="0.3s"
          >
            Why Smafleet
          </h2>
        </div>
        <div className="flex flex-wrap">
          <div className="w-full sm:w-1/2 md:w-1/2 space-y-5 lg:w-1/2">
            <div className="mx-4 wow fadeInRight" data-wow-delay="0.3s">
              <div>
                <h3 className="service-title">Business Process</h3>
                <p className="text-gray-600 text-justify">
                  Unlike other fleet management solutions, SMAFLEET is bundled with a highly optimized 
                  business process that caters for all the stakeholders involve in managing trucking operations.
                </p>
              </div>
            </div>
            <div className="mx-4 wow fadeInRight" data-wow-delay="0.3s">
              <div>
                <h3 className="service-title">Cloud-based</h3>
                <p className="text-gray-600 text-justify">
                  With SMAFLEET there is no additional cost for physical servers and maintenance, all you have
                  to do is focus in driving more profits by <a href="#">Getting Started</a>.
                </p>
              </div>
            </div>
            <div className="mx-4 wow fadeInRight" data-wow-delay="0.3s">
              <div>
                <h3 className="service-title">Ease of Use</h3>
                <p className="text-gray-600 text-justify">
                SMAFLEET Offers a superior user experience because it is designed by user-centric professionals and that is why even 
                the most inexperienced users can learn and navigate their way easily. With SMAFLEET all you have to worry about is driving 
                more profit.
                </p>
              </div>
            </div>

            <div className="mx-4 wow fadeInRight" data-wow-delay="0.3s">
              <div>
                <h3 className="service-title">Feature rich</h3>
                <p className="text-gray-600 text-justify">
                  Our product is packed with all the tools needed for managing entire fleet operation. These include tools for asset tracking, service and 
                  renewal reminders, maintenance management, invoicing management, trip and payment tracking, driver and technical employee management, 
                  as well as business intelligent and analytics tool.
                </p>
              </div>
            </div>
          </div>

          <div className="w-full shadow-xl rounded-xl mt-5 sm:w-1/2 md:w-1/2 lg:w-1/2">
            <div className="mx-4 wow fadeInRight" data-wow-delay="0.6s">
              <div className="flex content">
                <div className="w-full text-center">
                  <div className="flex justify-center">
                    <div
                      className="
                      items-center
                      
                      w-48
          
                      m-5
                      rounded-full
                    "
                    >
                      <Icon name="smafleetLogo" className="opacity-10" />
                    </div>
                  </div>

                  <h2
                    className="
                    text-4xl
                    font-bold
                    leading-snug
                    text-opacity-0 text-gray-100
                    mb-10
                    wow
                    fadeInUp
                  "
                    data-wow-delay="1s"
                  >
                    Keep Track of your fleet operation of your fleet with
                    smafleet
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
