import React from "react";
import Icon from "../utils/Icon";

function Trips() {
  return (
    <div className="container mt-4 py-20">
        <div className="flex flex-wrap sm:flex sm:flex-row-reverse">
            <div className="place-content-center w-full sm:w-1/2 md:w-1/2 space-y-5 lg:w-1/2">
                    <div className="mx-4 wow fadeInRight" data-wow-delay="0.3s">
                        <div className="place-content-center">
                            <h3 className="service-title">Tracking of trips and payment</h3>
                            <p className="text-gray-600 text-justify">
                              Keep records of your trips and seamlessly track the status of payment for each trip. This will
                              allow you to easily detect whether a trip has been paid or not.
                            </p>
                        </div>
                    </div>
            </div>
            <div className="w-full shadow-xl rounded-xl mt-5 sm:w-1/2 md:w-1/2 lg:w-1/2">
                <div className="mx-4 wow fadeInRight" data-wow-delay="0.6s">
                    <div className="flex contentd">
                        <div className="w-full text-center">
                            <div className="flex justify-center">
                                <div
                                className="
                                items-center
                                
                                w-48
                    
                                m-5
                                rounded-full
                                "
                                >
                                <Icon name="smafleetLogo" className="opacity-10" />
                                </div>
                            </div>

                            <h2
                                className="
                                text-4xl
                                font-bold
                                leading-snug
                                text-opacity-0 text-gray-100
                                mb-10
                                wow
                                fadeInUp
                            "
                                data-wow-delay="1s"
                            >
                                Keep Track of your fleet operation of your fleet with
                                smafleet
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
  );
}

export default Trips;
