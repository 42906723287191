import React, { useState } from "react";
import Icon from "../utils/Icon";
import Modal from "../utils/Modal";
import ReactGA from 'react-ga4';

const onClick = () =>{
  ReactGA.event({
    action: 'getstarted_action',
    category: 'getstarted_category',
    label: 'getstarted_label',
    value: 'XXXXXX',
  })
}

function HeroHome() {
  const [videoModalOpen, setVideoModalOpen] = useState(false);

  return (
    <section id="hero-area" className="bg-blue-100 pb-64 pt-48">
      <div className="container">
        <div className="bg-image"></div>
        <div className="flex justify-between top-50 content">
          <div className="w-full text-center">
            <div className="flex justify-center pt-32">
              <div className="absolute flex items-center">
                <Icon name="smafleetLogo" className="w-48 h-48  " />
              </div>
              <div className="rounded-full border-4 flex items-center ping-slow border-gray-400">
                <Icon
                  name="smafleetLogo"
                  className="w-48 h-48 opacity-10 rounded-full border-4"
                />
              </div>
            </div>

            <div className="">
              <h1
                className="
                text-2xl
                sm:text-4xl
                font-bold
                leading-snug
                text-gray-50
                mb-10
                wow
                fadeInUp
              "
                data-wow-delay="1s"
              >
                Get more out of your vehicles
              </h1>
            </div>

            <h2
              className="
              text-lg sm:text-2xl
              font-bold
              leading-snug
              text-opacity-100 text-gray-50
              mb-10
              wow
              fadeInUp
              sm:tracking-widest 
              tracking-wide
            "
              data-wow-delay="1s"
            >
              Be Highly Efficient, Smart and More Profitable
            </h2>
            <div className="mb-5">
              <a
                className="
                      text-gray-50
                      font-bold
                      bg-teal-500
                      border-2 border-teal-500
                      px-10
                      py-3
                      rounded-full
                      duration-300
                      hover:bg-teal-300 hover:text-white
                    "
                href="https://account.smafleet.com/getstarted"
                onClick={onClick}
              >
                Get Started
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroHome;
