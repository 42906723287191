import React from "react";

import Header from "../partials/Header";
import HeroHome from "../partials/HeroHome";
import Footer from "../partials/Footer";
import Credits from "../partials/Credits";
import Services from "../partials/Services";
import About from "../partials/About";
import Clients from "../partials/Clients";
import Contact from "../partials/Contact";
import Feature from "../partials/Feature";
import Features from "../partials/Features";
import Headersec from "../partials/Headersec";
import ReactGA from 'react-ga4';

ReactGA.initialize("G-EV1619S4JZ")
function Home() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">
        {/*  Page sections */}
        <HeroHome />
        <Features />
        <About />
        <Services />
        {/*<Feature />*/}
        {/* <Clients />
        <Contact /> */}
      </main>

      {/*  Site footer */}
      <Footer />
      {/* <Credits /> */}
      <a
        href="#"
        className="back-to-top w-10 h-10 fixed bottom-0 right-0 mb-5 mr-5 flex items-center justify-center 
        rounded-full bg-teal-500 text-white text-lg z-20 duration-300 hover:bg-teal-400 "
      >
        <i className="lni lni-arrow-up"></i>
      </a>
    </div>
  );
}

export default Home;
