import React from "react";
import { Link } from "react-router-dom";

function Services() {
  return (
    <section id="services" className="py-24">
      <div className="container">
        <div className="text-center">
          <h2
            className="mb-12 section-heading wow border-b fadeInDown"
            data-wow-delay="0.3s"
          >
            Key Features
          </h2>
        </div>
        <div className="flex flex-wrap">
          <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/3">
            <div className="m-4 wow fadeInRight" data-wow-delay="0.3s">
              <div className="icon text-5xl">
                <i className="lni lni-network"></i>
              </div>
              <div>
                <h3 className="service-title">Division of labour</h3>
                <p className="text-gray-600">
                  With SMAFLEET you can precisely define the role of each fleet stakeholder and what he can do to achieve efficient
                  division and administration of duties.
                </p>
              </div>
            </div>
          </div>

          <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/3">
            <div className="m-4 wow fadeInRight" data-wow-delay="0.6s">
              <div className="icon text-5xl">
                <i className="lni lni-bar-chart"></i>
              </div>
              <div>
                <h3 className="service-title">Real-time analytics</h3>
                <p className="text-gray-600">
                   Get real-time analytics to pinpoint exactly where improvement is needed and make informed decision without
                   wasting time on information gathering.
                </p>
              </div>
            </div>
          </div>

          <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/3">
            <div className="m-4 wow fadeInRight" data-wow-delay="0.9s">
              <div className="icon text-5xl">
                <i className="lni lni-infinite"></i>
              </div>
              <div>
                <h3 className="service-title">Information Flow Management</h3>
                <p className="text-gray-600">
                  With SMAFLEET, data is distributed and exchanged automatically between the intending parties as soon as entry is made. 
                  This enhances synergy, transparency and accountability as well as ensuring information consistency across all sources.

                </p>
              </div>
            </div>
          </div>

          <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/3">
            <div className="m-4 wow fadeInRight" data-wow-delay="1.2s">
              <div className="icon text-5xl">
                <i className="lni lni-car"></i>
              </div>
              <div>
                <h3 className="service-title">Driver Safety</h3>
                <p className="text-gray-600">
                  Automatically generate a deep analysis report on driving violations and safety compliance for both individual vehicles and the overall fleets. 
                </p>
              </div>
            </div>
          </div>

          <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/3">
            <div className="m-4 wow fadeInRight" data-wow-delay="1.5s">
              <div className="icon text-5xl">
                <i className="lni lni-stamp"></i>
              </div>
              <div>
                <h3 className="service-title">Payment vouchers</h3>
                <p className="text-gray-600">
                  Control your fleet finances efficiently through a standard financial management system that facilitates every expense transaction through
                  vouchers and stepwise approvals.
                </p>
              </div>
            </div>
          </div>

          <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/3">
            <div className="m-4 wow fadeInRight" data-wow-delay="1.8s">
              <div className="icon text-5xl">
                <i className="lni lni-investment"></i>
              </div>
              <div>
                <h3 className="service-title">Asset Account Ledger</h3>
                <p className="text-gray-600">
                  Continously track and monitor the financial standings of your assets through asset account ledger for both individual and overall assets.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Services;
