import React, { useState, useEffect } from "react";
import Icon from "../utils/Icon";
import Login from "./Login";
import ReactGA from 'react-ga4';
import { stringify } from "postcss";

const onClick = () =>{
    ReactGA.event({
      action: 'demo_action',
      category: 'demo_category',
      label: 'demo_label',
      value: 'XXXXXX',
    })
}

function Header() {
  const [top, setTop] = useState(true);

  // detect whether user has scrolled the page down by 10px
  useEffect(() => {
    const scrollHandler = () => {
      window.pageYOffset > 10 ? setTop(false) : setTop(true);
    };
    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, [top]);

  return (
    // Header Area wrapper Starts
    <header id="header-wrap" className="relative">
      <div className="navigation fixed top-0 left-0 w-full z-30 duration-300">
        <div className="container">
          <nav
            className="
              navbar
              py-2.5
              px-4 lg:px-6
              relative
              flex
              justify-between
              items-center
              duration-300
            "
          >
            <a className="navbar-brand flex items-center" href="#">
              <Icon name="smafleetLogo" className="w-12 h-12" />
              <span className="text-2xl text-gray-400 flex font-medium">
                Sma <span className="text-teal-500">fleet</span>
              </span>
            </a>

            <div
              class="
              collapse
              navbar-collapse
              hidden
              lg:block
              duration-300
              shadow
              absolute
              top-100
              left-0
              mt-full
              bg-white
              z-20
              px-5
              py-3
              w-full
              lg:static lg:bg-transparent lg:shadow-none
            "
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav mr-auto justify-center items-center lg:flex">
                <li className="nav-item">
                  <a className="page-scroll active" href="#hero-area">
                    Home
                  </a>
                </li>
                <li className="nav-item">
                  <a className="page-scroll" href="#features">
                    Product
                  </a>
                </li>
                <li className="nav-item">
                  <a className="page-scroll" href="#about">
                    Why Smafleet
                  </a>
                </li>
                <li className="nav-item">
                  <a className="page-scroll" href="#services">
                    Features
                  </a>
                </li>
                <li className="nav-item">
                  <a className="page-scroll" href="#contact">
                    Pricing
                  </a>
                </li>
              </ul>
            </div>
            <div
              className="
                flex items-center
              "
            >
              <a 
              className="
              font-bold
              px-4
              text-sm
              py-2
              rounded-md
              duration-300
              text-gray-400
              hover:text-teal-300
              "
              href="https://account.smafleet.com"
              >Login</a>
              <a
                className="
                  bg-teal-500
                  font-bold
                  whitespace-nowrap
                  px-4
                  text-sm
                  py-2
                  rounded-md
                  duration-300
                  text-gray-50
                  hover:bg-teal-300
                "
                href="https://account.smafleet.com/getstarted"
                onClick={onClick}
              >
                Free Demo
              </a>
            </div>
            <button
              className="navbar-toggler focus:outline-none block lg:hidden"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="toggler-icon"></span>
              <span className="toggler-icon"></span>
              <span className="toggler-icon"></span>
            </button>
          </nav>
        </div>
      </div>
    </header>
  );
}

export default Header;
