import React from "react";
import Icon from "../utils/Icon";

function Consoltrack() {
  return (
      <div className="container">
        <div className="flex flex-wrap">
          <div className="place-content-center w-full sm:w-1/2 md:w-1/2 space-y-5 lg:w-1/2">
            <div className="mx-4 wow fadeInRight" data-wow-delay="0.3s">
              <div className="place-content-center">
                <h3 className="service-title">Consolidated asset tracking</h3>
                <p className="text-gray-600 text-justify">
                  Define and control your asset route, track location, status, movement and progress of your asset within a single interface.
                  Yes our tracking solution is more than Location on a map!
                </p>
              </div>
            </div>
          </div>

          <div className="w-full shadow-xl rounded-xl mt-5 sm:w-1/2 md:w-1/2 lg:w-1/2">
            <div className="mx-4 wow fadeInRight" data-wow-delay="0.6s">
              <div className="flex contenta">
                <div className="w-full text-center">
                  <div className="flex justify-center">
                    <div
                      className="
                      items-center
                      
                      w-48
          
                      m-5
                      rounded-full
                    "
                    >
                      <Icon name="smafleetLogo" className="opacity-10" />
                    </div>
                  </div>

                  <h2
                    className="
                    text-4xl
                    font-bold
                    leading-snug
                    text-opacity-0 text-gray-100
                    mb-10
                    wow
                    fadeInUp
                  "
                    data-wow-delay="1s"
                  >
                    Keep Track of your fleet operation of your fleet with
                    smafleet
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    
  );
}

export default Consoltrack;
